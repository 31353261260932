<template>
    <nav class="navbar navbar-expand-lg navbar-light" :class="{ 'bg-white': theme === 'light', 'bg-dark': theme === 'dark'}">
        <div class="container-fluid">
            <a class="navbar-brand" href="javascript:;">{{ platformName }}</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="navbarCollapse">
                <ul class="navbar-nav me-auto mb-2 mb-sm-0">
                    <li class="nav-item">
                        <router-link :to="{ name: 'home' }" class="nav-link active" aria-current="page">{{ $t("Home") }}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link  :to="{ name: 'terms' }" class="nav-link" href="#">{{ $t("Terms of use") }}</router-link>
                    </li>
                </ul>
                <form class="d-flex">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-success" type="submit">{{ $t("Search") }}</button>
                </form>

                <div v-if="loggedIn" class="top-bar-login-container">
                    <div class="dropdown">
                        <span class="top-bar-hello-message">{{  renderName(profileName) }}</span>
                        <button type="button" class="top-bar-button-img" :title="$t('User settings')" data-bs-toggle="dropdown" aria-expanded="false">
                            <img v-if="profileImage" class="btn-image" :src="profileImage" />
                            <img v-else class="btn-image" src="@/assets/user.png" />
                        </button>
                        <div class="dropdown-menu" 
                            :class="{ 'dropdown-menu-end': windowInnerWidth > 992, 'bg-white': theme === 'light', 'bg-dark': theme === 'dark'}">
                            <AccountSettingsDropdown @openModal="openModal"></AccountSettingsDropdown>
                        </div>
                    </div>
                </div>

                <div v-if="!loggedIn" class="top-bar-login-container">
                    <button type="button" class="btn" :title="$t('Select your language')" @click="selectLanguage">
                        <i class="fas fa-language"></i>
                    </button>

                    <button type="button" class="btn" :title="$t('Change theme')" @click="invertTheme">
                        <i v-if="theme === 'dark'" class="fas fa-sun"></i>
                        <i v-else class="fas fa-moon"></i>
                    </button>

                    <button type="button" @click="login" class="btn btn-primary">
                        <i class="fas fa-sign-in"></i> {{ $t("Login") }}
                    </button>
                </div>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { ColorThemeName, getTheme, setTheme } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import AccountSettingsDropdown from "@/components/dropdowns/account/AccountSettingsDropdown.vue";

export default defineComponent({
    components: {
        AccountSettingsDropdown,
    },
    name: "TopBar",
    emits: ["toggle-menu", "openModal"],
    setup: function () {
        return {
            resizeObserver: null as ResizeObserver,
        };
    },
    data: function () {
        return {
            platformName: import.meta.env.VITE__PLATFORM_NAME || "Platform",
            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            theme: getTheme(),
            profileName: AuthController.ProfileName || AuthController.Username || "",
            windowInnerWidth: window.innerWidth,
        };
    },
    methods: {
        openModal: function(d: string) {
            this.$emit("openModal", d);
        },

        openUserSettings: function () {
            this.$emit("openModal", "account-settings");
        },

        selectLanguage: function () {
            this.$emit("openModal", "change-language-modal");
        },

        invertTheme: function () {
            setTheme(this.theme === "dark" ? "light" : "dark");
        },

        login: function () {
            this.$requireLogin();
        },

        clickMenu: function () {
            this.$emit("toggle-menu");
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.Username || "";
            this.profileImage = AuthController.ProfileImage;
        },

        onThemeChanged: function (t: ColorThemeName) {
            this.theme = t;
        },

        renderName: function (name: string): string {
            return ((name + "").split(" ")[0] + "").split(",")[0] || "???";
        },

        onPageResize: function () {
            this.windowInnerWidth = window.innerWidth;
        },
    },
    mounted: function () {
        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        this.$listenOnAppEvent("theme-changed", this.onThemeChanged.bind(this));

        if (window.ResizeObserver) {
            this.resizeObserver = new ResizeObserver(this.onPageResize.bind(this));
            this.resizeObserver.observe(this.$el);
        }
    },
    beforeUnmount: function () {},
});
</script>

<style scoped>
.top-bar-button-img {
    display: inline-block;
    width: 40px;
    height: 40px;
    box-shadow: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    background: transparent;
    color: var(--theme-btn-color);
    border-radius: 50%;
    padding: 0;
}

.top-bar-button-img .btn-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.top-bar-hello-message {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 15px;
}

.navbar-collapse .navbar-nav {
    padding: 0.5rem;
}

.top-bar-login-container {
    padding: 0.5rem;
}
</style>
